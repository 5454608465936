.item {
    background-color: #F0F0F0;
    flex-direction: row;
    display: flex;

    border-radius: 10px;
    -webkit-box-shadow: 0 0 1px 0 rgb(23 24 25);
    -moz-box-shadow: 0 0 1px 0 rgba(23, 24, 25, 1);
    box-shadow: 0 0 1px 0 rgb(23 24 25);
    margin-bottom: 40px;
    padding: 20px;
    border: 2px solid #fff;

    &-wrapper__categories {
        margin-top: 33px;
    }

    &-content {
        padding: 0 15px;
        &__wrapper {
            border-top: 1px solid #dedede;
            margin: 50px 0;
            padding: 30px 0;

        }

        &__title {
            cursor: pointer;
            font-size: 24px;
            font-weight: 700;
            margin: 10px 0 20px 0;
        }
    }
}

.item:hover {
    border-color: #6101d1;
}

.item .item-body .item-name {
    color: #225378;
    font-size: 18px;
    text-decoration: none;
    font-weight: bold;
}
.item .item-body .item-name:hover {
    cursor: pointer;
    text-decoration: underline;
}

.item .item-logo .item-logo__wrapper, .item .item-logo .item-logo__wrapper img {
    width: 100%;
}

.item .item-logo .item-logo__wrapper {
    float: left;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color: #fff;
    position: relative;
    box-shadow: inset 0 0 0 1px rgb(0 0 0 / 10%);
    -webkit-box-shadow: inset 0 0 0 1px rgb(0 0 0 / 10%);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
}

.item-actions {
    display: flex;
    justify-content: end;
}

.item-actions div {
    margin-right: 15px;
}
.item-actions div svg {
    margin: 0 5px 0 10px;
}

.item-header-logo-wrapper {
    z-index: 2;
    float: left;
    display: block;
    background: #fff;
    width: 194px;
    height: 194px;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 5px;
    margin-right: 20px;
}

.item-header-logo {
    float: left;
    width: 174px;
    height: 174px;
    border-radius: 50%;
    background-color: #fff;
    position: relative;
    box-shadow: inset 0 0 0 1px rgb(0 0 0 / 10%);
    -webkit-box-shadow: inset 0 0 0 1px rgb(0 0 0 / 10%);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
}

.item-header-actions {
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
    padding: 10px 0;
    min-height: 100px;
}

.item-header-description {
    padding-top: 45px;
}