.header {
    background-color: white;
    box-shadow: 0 2px 7px 1px rgb(0 0 0 / 7%);
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 65px;
}

.header .navbar {
    height: 65px;
}

.navbar-brand img {
    width: 114px;
    height: 38px;
}

.header-search input {
    height: 42px;
    padding: 0 20px 0 50px;
    display: inline-block;
    font-size: 14px;
    vertical-align: middle;
    border-radius: 5px;
    border: 1px solid #d7d7d7;
    background: #f9f9f9;
    -webkit-transition: border 0.2s linear 0s;
    transition: border 0.2s linear 0s;
}
.header-search input:focus {
    outline: none;
    border-color: #225378;
}

.header-search svg {
    margin-left: 16px;
    position: absolute;
    margin-top: 13px;
    color: #d7d7d7;
}

.header .nav-link {
    color: #3e3e3e;
    text-decoration: none;
    margin-right: 15px;
    font-size: 15px;
    padding: 5px;
    border: solid 1px transparent;
    transition: all 0.2s ease-in-out;
    align-items: center;
    display: flex;
}

.header .nav-link.nav-hover:hover {
    border: 1px solid #8040c0;
    border-radius: 5px;
}

.header .nav-link.first-link {
    cursor: pointer;
    border: none;
}

.header .nav-link.first-link svg {
    color: black;
    width: 20px;
    height: 20px;
    margin-right: 16px;
    border: none;
}

.header .nav-link.first-link:hover {
    border: none;
    opacity: 0.7;
}

.header .nav-link svg {
    color: #8040c0;
    width: 13px;
    height: 13px;
    margin-right: 5px;
}

.header .dropdown {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border: 2px solid #8040c0;
    border-top: 0;
    position: absolute;
    top: 66px;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 79%;
    margin: 0 auto;
    background: #fff;
    z-index: 10;
    padding: 20px;
    -webkit-box-shadow: 0 2px 7px 1px rgb(0 0 0 / 7%);
    box-shadow: 0 2px 7px 1px rgb(0 0 0 / 7%);
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
}

.header .dropdown .dropdown-block {
    display: inline-flex;
    flex-direction: column;
    padding: 0 10px 0 30px;
    margin-bottom: 5px;
}
.header .dropdown .dropdown-block:last-of-type {
    margin-bottom: 0;
}

.header .dropdown .dropdown-block .dropdown-item {
    display: inline-flex;
    width: 100%;
    font-weight: 700;
    padding: 5px;
}
.header .dropdown .dropdown-block .dropdown-item:hover {
    background: #8040c0;
    color: #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    cursor: pointer;
}
.header .dropdown .dropdown-separator {
    display: inline-flex;
    height: 77px;
    width: 1px;
    background-image: url("../../static/images/separator.png");
}

.dropdown-mobile, .mobile-search {
    display: none;
}

.header {
    .dropdown .active,
    .dropdown-mobile .active,
    .dropdown-item:active {
        background: inherit;
        &.active {
            color: #8040c0;
        }
    }
}

@media (max-width: 1200px) {
    .header .container {
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 1000px) {
    .header-search {
        position: absolute;
        right: 30px;
        top: 75px;
    }
}

@media (max-width: 768px) {
    .dropdown {
        display: none !important;
    }
    .header-search {
        display: none;
    }

    .dropdown-mobile {
        list-style: none;
        padding: 0;
        border: 0;
        z-index: 10;
        background: #fff;
        margin: 0;
        top: 2px;
        display: block;
    }

    .dropdown-mobile li {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid #dedede;
        padding: 0 30px;
        align-content: center;
        display: inline-flex;
        align-items: center;
    }

    .nav-item-display {
        display: none;
    }

    .mobile-search {
        display: block;
    }
}