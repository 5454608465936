$xlg: 1140px;
$lg: 960px;
$md: 720px;
$sm: 540px;

$text-color: #3e3e3e;

$background: 'red';

@mixin mobile {
  @media (max-width: $sm) {
    @content
  }
}

@mixin ipad {
  @media (max-width: $md) {
    @content
  }
}