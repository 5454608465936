/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/)
 * Copyright 2013-2016 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap/blob/gh-pages/LICENSE)
 */

body {
    overflow-x: hidden;
}

/* Toggle Styles */

#wrapper {
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled {
    padding-left: 250px;
}

#sidebar-wrapper {
    z-index: 1000;
    position: fixed;
    left: 250px;
    width: 0;
    height: 100%;
    margin-left: -250px;
    overflow-y: auto;
    border-right: 2px solid #6101d1;
    background: #FFFFFF;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
    width: 250px;
}

#page-content-wrapper {
    width: 100%;
    position: absolute;
    padding: 15px;
}

#wrapper.toggled #page-content-wrapper {
    position: absolute;
    margin-right: -250px;
}

/* Sidebar Styles */

.sidebar-nav {
    position: absolute;
    top: 0;
    width: 250px;
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebar-nav .sidebar-brand img {
    width: 130px;
}

.sidebar-nav li {
    text-indent: 20px;
    line-height: 40px;
    position: relative;
}

.sidebar-nav li svg {
    right: 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #6101d1;
    cursor: pointer;
}

.sidebar-nav li.sidebar-active svg {
    color: white;
}

.sidebar-nav li a:hover + svg {
    color: white;
}

.sidebar-nav li a {
    display: block;
    text-decoration: none;
    color: #151515;;
}

.sidebar-nav li a:hover  {
    text-decoration: none;
    color: #fff;
    background: #8d2cff;
}

.sidebar-nav li.sidebar-active a  {
    text-decoration: none;
    color: #fff;
    background: #6101d1;
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
    text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
    height: 65px;
    font-size: 18px;
    line-height: 60px;
}

.sidebar-nav > .sidebar-brand a {
    color: #999999;
}

.sidebar-nav > .sidebar-brand a:hover {
    color: #fff;
    background: none;
}

.sidebar-footer {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
}
.sidebar-footer .btn {
    outline: none;
    color: white;
    background-color: #6101d1;
}
.sidebar-footer .btn:hover {
    background: #8d2cff;
}

@media(min-width:768px) {
    #wrapper {
        padding-left: 250px;
    }

    #wrapper.toggled {
        padding-left: 0;
    }

    #sidebar-wrapper {
        width: 250px;
    }

    #wrapper.toggled #sidebar-wrapper {
        width: 0;
    }

    #page-content-wrapper {
        padding: 20px;
        position: relative;
    }

    #wrapper.toggled #page-content-wrapper {
        position: relative;
        margin-right: 0;
    }
}
