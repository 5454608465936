.categories {
  .title {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 43px;

    background: #8040c0;
    border-radius: 5px 5px 0 0;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
  }

  .list {
    border: 1px solid #dddddd;
    border-top: 0;
    font-size: 13px;
    padding: 20px 20px 10px;

    &-item {
      cursor: pointer;
      color: #285576;
      list-style: none;
      font-weight: bold;
      padding: 0 10px;
      margin: 10px 0;
      transition: all 0.2s ease-in-out;
    }
  }
}