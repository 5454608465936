@import "src/styles/mixin";

.footer {
    background-color: transparent;
}

.footer-first_block {
    border-style: solid;
    border-width: 1px 0 1px 0;
    border-color: #DEDEDE;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 0;
    font-size: 12px;
}
.footer-first_block .container {
    justify-content: space-between;
    display: flex;
    max-width: 55%;
    margin-top: 51px;
    margin-bottom: 38px;
    align-content: center;
}

.footer-first_block__left-side, .footer-first_block__right-side {
    display: inline-flex;
    align-items: center;
}

.footer-first_block__right-side {
    padding-right: 50px;
}
.footer-first_block__right-side .fab {
    margin-left: 15px;
}

.footer-first_block__left-side {
    padding-left: 50px;
}
.footer-first_block__left-side span {
    margin-left: 40px;
}

.footer-first_block__left-side b {
    margin-left: 20px;
}

.copyright-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 100px;
}
.copyright-block p:first-of-type {
    margin-bottom: 10px !important;
}
.copyright-block p {
    color: #AEAEAC;
    font-size: 12px;
}

@include ipad {
    .footer {
        .container-fluid .container {
            .footer-first_block__right-side {
                display: flex;
                justify-content: center;
                padding-right: 0;
            }
            
            &, .footer-first_block__left-side {
                display: flex;
                flex-direction: column;

                b,
                span {
                    padding: 0;
                    margin-left: 0;
                    margin-top: 10px;
                }
            }
        }
    }
}

@include mobile {
    .footer {
        .container-fluid .container {
            display: flex;
            flex-direction: column;
        }
    }
}